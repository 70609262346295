<template>
  <div>
    <gSwiperCommon :bannerType="'show.page.home.pc.en'"></gSwiperCommon>
    <div class="demand-detail">
      <div class="g-layout">
        <div class="g-layout-r" style="margin-left: 0">
          <div v-if="supplyDetail">
            <div class="m-card">
              <div class="home-l">
                <div class="detail">
                  <div class="detail-top" >
                    <img
                      v-if="supplyDetail.publisher&&supplyDetail.publisher.avatarUrl"
                      :src="supplyDetail.publisher.avatarUrl"
                      alt=""
                      class="avatarUrl"
                      @click="linkToDetail(supplyDetail)"
                    />
                    <img
                      v-else-if="supplyDetail.type=='Purchase'"
                      src="@/assets/img/user.png"
                      alt=""
                      class="avatarUrl"
                      @click="linkToDetail(supplyDetail)"
                    />
                    <img
                      v-else
                      :src="exhibitionInfo.logo"
                      alt=""
                      class="avatarUrl"
                      @click="linkToDetail(supplyDetail)"
                    />
                    <div class="top-right" 
                      @click="linkToDetail(supplyDetail)">
                      <div class="name" v-if='supplyDetail.publisher&&supplyDetail.publisher.publisherNameEn'>{{ supplyDetail.publisher.publisherNameEn}}
                          <span v-if="supplyDetail.publisher&&supplyDetail.publisher.jobTitle ">{{ supplyDetail.publisher.jobTitle }}</span></div>
                      <div class="company" v-if="supplyDetail.publisher&&supplyDetail.publisher.compNameEn">{{ supplyDetail.publisher.compNameEn }}</div>
                      <!-- <template v-if="userInfo&&userInfo.userId!=supplyDetail."> -->
                        <div class="follow" v-if="!isFollow&&login"   @click.stop="supplyfollower(supplyDetail)">Follow</div>
                        <div class="follow" v-else-if="isFollow&&login"  @click.stop="deSupplyFollower(supplyDetail)">Followed</div>
                      <!-- </template> -->
                    </div>
                  </div>
                  <div class="email" v-if="(supplyDetail.type=='Purchase'&& userRole=='EXHIBITOR')||supplyDetail.type=='Supply'">
                    <div >Phone：<span v-if="supplyDetail.publisher&&supplyDetail.publisher.phoneNumber">{{supplyDetail.publisher.phoneNumber}}</span></div>
                    <div >Email：<span v-if="supplyDetail.publisher&&supplyDetail.publisher.email">{{supplyDetail.publisher.email}}</span></div>
                  </div>
                  <div class="email1" v-else>
                    <div>Only exhibitors can view contact information</div>
                    <!-- <div style="color: #1890ff; cursor: pointer; margin-top: 5px" @click="linkToRegSteps">
                      Register now
                    </div> -->
                  </div>

                  <div class="title" v-if="supplyDetail.type">
                    <span class="title-span">{{supplyDetail.type}}</span>
                    <span class="title-span2">{{supplyDetail.productName}}</span>
                  </div>
                  <div class="content">
                    <p v-html="supplyDetail.content">
                      
                    </p>
                  </div>
                  <div class="demand_video"  @contextmenu.prevent="rightClcik()" v-if="supplyDetail.videos&&supplyDetail.videos.url">
                    <video
                      controlsList="nodownload"
                      :src="supplyDetail.videos.url"
                      :poster="supplyDetail.videos.cover"
                      controls="controls"
                      style="max-width: 100%"
                    >Your browser does not support the "video" tag。</video>
                  </div>
                  <div class="demand-img" v-else-if="supplyDetail.imgUrls">
                    <img :src="item" alt=""
                      v-for="(item, index) in supplyDetail.imgUrls"
                      :key="index"
                    />
                  </div>
                  <div class="demand-img" v-else>
                    <div style="width:150px;height:150px">

                    </div>
                  </div>
                  <div class="demand-bottom">
                    <span>{{supplyDetail.viewCount}} view</span>
                    <span>{{supplyDetail.commentCount}} comment</span>
                    <div class="time">{{supplyDetail.updateAt}}</div>
                  </div>
                </div>
                
              </div>
              
            </div>
            <evaluate :supplyId="supplyId" :supply="true" :evalCount="supplyDetail.commentCount" v-if="supplyDetail.commentCount>0"></evaluate>
          </div>
          <div class="u-empty" v-if="dataLoading">{{ $t("lang.loading") }}</div>
          <div class="u-empty" v-if="!dataLoading && !supplyDetail">{{ $t("lang.No_data") }}</div>
        </div>
        <div class="g-layout-l m-card3" style="margin-left: 1.66667%">
          <div class="mc-top">
            <div class="mc-top-title">
              <span class="mc-top-txt s-bold s-primary">Demands</span>
            </div>
          </div>
          <div class="demand-r">
            <div class="demand-r-list" v-if="demandList && demandList.length">
              <!--供需详情-->
              <div
                :key="index"
                class="supply"
                v-for="(item, index) in demandList"
              >
                <div
                  :title="item.content"
                  style="padding: 10px; width: 266px"
                  @click.stop="golinkToDemandDetail(item.id,item.isFollow)"
                >
                  <div class="supply-top">
                    <img
                      :src="item.publisher.avatarUrl"
                      class="headerimg"
                      v-load="'oPicXS'"
                      v-if="item.publisher && item.publisher.avatarUrl"
                    />
                  <img
                    v-else-if="item.type=='Purchase'&&item.publisherType==0"
                    src="@/assets/img/user.png"
                    alt=""
                    class="headerimg"
                  />
                  <img
                    v-else
                    :src="exhibitionInfo.logo"
                    alt=""
                    class="headerimg"
                  />
                    <div v-if="item.publisher">
                      <div class="name">
                        <span
                          :title="item.publisher.publisherNameEn"
                          class="name1"
                          >{{ item.publisher.publisherNameEn }}</span
                        >
                        <span
                          :title="item.publisher.jobTitle"
                          class="name2"
                          style
                          >{{ item.publisher.jobTitle }}</span
                        >
                      </div>
                      <div class="company">
                        {{ item.publisher.compNameEn }}
                      </div>
                    </div>
                    <div
                      @click.stop="supplyfollower(item)"
                      class="guanzhu"
                      v-if="
                        !item.isFollow &&
                        SupplymemberId &&
                        item.memberId != userInfo.inMember.memberId &&
                        item.member
                      "
                    >
                      {{ $t("lang.Follow") }}
                    </div>
                    <div
                      @click.stop="deSupplyFollower(item)"
                      class="yiguanzhu"
                      v-if="
                        item.isFollow &&
                        SupplymemberId &&
                        item.memberId != userInfo.inMember.memberId
                      "
                    >
                      {{ $t("lang.Followed") }}
                    </div>
                  </div>
                  <div class="supply-center">
                    <div class="category-content">
                      <span class="category" v-if="item.type">{{
                        item.type
                      }}</span>
                      <span class="category-text">{{ item.productName }}</span>
                    </div>
                    <div
                      :title="item.content"
                      class="category-text2"
                      v-html="item.content"
                    >
                      
                    </div>
                  </div>
                  <div class="supply-bottom">
                    <div class="video_wrap" v-if="item.videos">
                      <div class="top_video"  v-for="(video,ind) in item.videos" :key="ind" >
                        <div class="video_mask">
                          <i class="iconfont icon-shipinmianxing"></i>
                        </div>
                        <img :src="video.cover"> 
                      </div>
                    </div>
                    <div v-else-if="item.imgUrls&&item.imgUrls.length">
                      <img :src="item.imgUrls[0]" class="img1" v-if="item.imgUrls[0]" v-load="'oPicXS'" />
                      <img :src="item.imgUrls[1]" class="img2" v-if="item.imgUrls[1]" v-load="'oPicXS'" />
                      <img :src="item.imgUrls[2]" class="img3" v-if="item.imgUrls[2]" v-load="'oPicXS'" />
                      <img :src="item.imgUrls[3]" class="img4" v-if="item.imgUrls[3]" v-load="'oPicXS'" />
                      <img :src="item.imgUrls[4]" class="img5" v-if="item.imgUrls[4]" v-load="'oPicXS'" />
                    </div>
                    <div class="empty" v-else>
                      <div class="empty-img"></div>
                    </div>
                    <div class="time">
                      <div
                        class="u-icon"
                        style="
                          margin-bottom: 5px;
                          justify-content: flex-end;
                          padding-right: 0;
                        "
                      >
                        <span
                          ><i
                            style="margin-left: 4px"
                            class="icon iconfont icon-liulanliang"
                          ></i
                          >{{ item.viewCount }}</span
                        >
                        <span
                          ><i
                            style="margin-left: 4px"
                            class="icon iconfont icon-msg"
                          ></i
                          >{{ item.commentCount }}</span
                        >
                      </div>
                      <div>{{ item.updateAt }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="demand-r-list" v-if="!supplyDetailLoading && !demandList.length">
              <div style="text-align: center; margin-top: 200px; width: 100%">
                {{ $t("lang.No_data") }}
              </div>
            </div>
            <div class="demand-r-list" v-if="supplyDetailLoading">
              <div style="text-align: center; margin-top: 200px; width: 100%">
                {{ $t("lang.loading") }}
              </div>
            </div>
          </div>
          <div class="fenge"></div>
          <div class="code" v-if="isNew">
               <div ref="qrCodeUrl" style="width: 220px; height: 220px; margin: 0 auto"></div>
              <span>{{ $t("lang.Scan_code_to_view_mobile_terminal") }}</span>
            </div>
          </div>
        </div>


      </div>
     
    </div>
  </div>
</template>

<script>
import gSwiperCommon from "@components/gSwiperCommon/gSwiperCommon.vue";
import { mapGetters, mapActions } from "vuex";
import evaluate from "@components/evaluate/index.vue";
import QRCode from "qrcodejs2";
import url from "@/utils/url";

export default {
  components: {
    gSwiperCommon,
    evaluate,
  },
  data() {
    return {
      url: url,
      totalCount: "",
      demandList: [],
      page: 1,
      size: 2,
      qrcode: "",
      supplyDetail: "",
      isFollow:'',
      isNew:false,
      dataLoading: true,
      supplyDetailLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      searchType: "getSearchType",
      searchTxt: "getSearchTxt",
      exhibitionId: "getExhibitionId",
      userInfo: "getUser",
      formHeaders: "getFormHeaders",
      uploadUrl: "getUploadUrl",
      exhibitionInfo: "getExhibitionInfo",
      login: "getLogin",
    }),
    isRegOk() {
      return (
        this.userInfo &&
        this.userInfo.inMember &&
        this.userInfo.inMember.userRole &&
        this.userInfo.inMember.userRole != "VISITOR" &&
        this.userInfo.inMember.userRole != "Visitor"
      );
    },
    userRole() {
      return this.userInfo &&
        this.userInfo.inMember &&
        this.userInfo.inMember.userRole
        ? this.userInfo.inMember.userRole
        : "";
    },
    isFollow1() {
      return this.$route.query.isFollow=='true'?true:false;
    },
  },
  created() {
    document.title = this.exhibitionInfo
      ? this.exhibitionInfo.nameEn
      : "ExShow";
      this.supplyId=this.$route.query.id;
      this.isFollow=this.isFollow1;
    this.init();
    this.getSupply();
    
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXSEn",
      supplyDemand: "news/supplyDemand",
      supplyFollower: "news/supplyFollower",
      deleteSupplyFollower: "news/deleteSupplyFollower",
      unverifiedDialog: "unverifiedDialog",
      linkToDemandDetail: "linkTo/linkToDemandDetail",
      addView: 'loginModule/addView',
      addAllView: 'addAllView',
      linkToRegSteps: "linkTo/linkToRegSteps",
      linkToLogin: "linkTo/linkToLogin",
    }),
    async getSupply() {
      let query = `
        query($id: String!){
          supplyDemandQuery{
            get(id:$id){
                content
                imgUrls
                videos
                memberId 
                productName 
                publisherType
                publishId
                viewCount
                updateAt
                type
                commentCount
                id
                auditTime
                publisher {
                  avatarUrl
                  publisherName
                  booth
                  categroy
                  compName
                  compNameEn
                  publisherNameEn
                  jobTitle
                  phoneNumber
                  email
                }
             
            }
          }
        }   
        `;
      let opt = {
        query: query,
        variables: {
          id: this.supplyId,
        },
      };
      let data = await this.graphqlPost(opt);
      console.log(data, "data");
      this.supplyDetail = data.data.supplyDemandQuery.get;
      if (this.supplyDetail) {
        if (this.supplyDetail.imgUrls) {
          this.supplyDetail.imgUrls = this.supplyDetail.imgUrls.split(";");
        } 
        if(this.supplyDetail.videos){
          this.supplyDetail.videos=JSON.parse(this.supplyDetail.videos)[0]
        }
        this.supplyDetail.auditTime = moment(
          this.supplyDetail.auditTime
        ).format("YYYY/MM/DD");
      }
      this.dataLoading = false;
    },
    async init() {
      if (!this.isRegOk) {
        this.SupplymemberId = "";
        this.query = `
        query($query:QueryInput!){
          supplyDemandQuery{
            query(query:$query){
              hasNextPage
              pageIndex
              totalCount
              items {
                content
                imgUrls
                videos
                memberId 
                productName 
                publisherType
                viewCount
                updateAt
                type
                commentCount
                id
                auditTime
                publisher {
                  avatarUrl
                  publisherName
                  booth
                  categroy
                  compName
                  compNameEn
                  publisherNameEn
                  jobTitle
                }
              }
            }
          }
        }
      `;
      } else {
        this.SupplymemberId = this.userInfo.inMember.memberId;
        this.query = `
        query($query:QueryInput!){
          supplyDemandQuery{
            query(query:$query){
                hasNextPage
                pageIndex
                totalCount
                items {
                  content
                  productName
                  imgUrls
                  videos
                  memberId
                  isFollow(memberId:"${this.userInfo.inMember.memberId}")
                  publisherType
                  viewCount
                  updateAt
                  type
                  commentCount
                  id
                  auditTime
                  publisher {
                    avatarUrl
                    publisherName
                    booth
                    categroy
                    compName
                    compNameEn
                    publisherNameEn
                    jobTitle
                  }
                }
              }
            }
        }
      `;
      }

      let where = {
        Equal: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
        Equal1: {
          n: "auditState",
          v: 1,
        },
        Equal_isdeleted: {
          n: "isDeleted",
          v: false,
        },
      };
      let order = [{ N: "updateAt", v: 0 }];
      let opt = {
        query: this.query,
        variables: {
          query: {
            page: this.page,
            size: this.size,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };

      let data = await this.graphqlPost(opt);
      this.totalCount = data.data.supplyDemandQuery.query.totalCount;
      this.demandList = data.data.supplyDemandQuery.query.items;
      if (this.demandList) {
        this.demandList.forEach((c) => {
          if (c.imgUrls) {
            c.imgUrls = c.imgUrls.split(";");
          }
          if(c.videos){
            c.videos=JSON.parse(c.videos);
          }
          c.auditTime = moment(c.auditTime).format("YYYY/MM/DD");
        });
      }
      this.supplyDetailLoading = false;
      this.creatQrCode("qrCodeUrl");
      this.addView()
    },
        //供需关注
    async supplyfollower(item) {
      if (!this.login) {
        this.$Modal.confirm({
          title: "Not signed in",
          content: "Please sign in first",
          okText: "Sign in",
          onOk: () => {
            that.linkToLogin();
          },
          onCancel: () => {},
        });
        return
      }
      let userInfo = {
        name: this.userInfo.name,
        avatarUrl: this.userInfo.avatarUrl,
        nickName: this.userInfo.nickName,
      };
      let opt = {
        supplyDemandId: item.id,
        memberId: this.userInfo.inMember.memberId,
        userInfo: JSON.stringify(userInfo),
      };
      let data = await this.supplyFollower(opt);
      console.log(data);
      if (data.result) {
        this.$Message.success("Successfully");
        this.isFollow=true
      } else {
        this.$Message.warning("Fail");
      }
    },
    //取消关注
    async deSupplyFollower(item) {
      if (!this.login) {
        this.$Modal.confirm({
          title: "Not signed in",
          content: "Please sign in first",
          okText: "Sign in",
          onOk: () => {
            that.linkToLogin();
          },
          onCancel: () => {},
        });
        return
      }
      let opt = {
        supplyDemandId: item.id,
        memberId: this.userInfo.inMember.memberId,
      };
      let data = await this.deleteSupplyFollower(opt);
      console.log(data);
      if (data.result) {
        this.$Message.success("Successfully");
        this.isFollow=false
      } else {
        this.$Message.warning("Fail");
      }
    },
    //创建二维码
    creatQrCode(ref) {
      this.isNew=true;
      this.$nextTick(()=>{
        let url = `https://${this.url.mobileEn}/needDetails/`+this.supplyId;
        this.qrcode = new QRCode(this.$refs[ref], {
          text: url, // 需要转换为二维码的内容
          width: 220,
          height: 220,
          colorDark: "#000",
          colorLight: "#fff",
          correctLevel: QRCode.CorrectLevel.H,
        });
      })
    },
    //添加浏览
    addView() {
      let opt = {
        exhibitionId: this.exhibitionId,
        target: 'supplyDemand',
        targetId: this.supplyId,
      }
      if (this.userInfo && this.userInfo.userId) {
        opt.userId = this.userInfo.userId
      }
      let data= this.addAllView(opt)
    //   this.supplyDetail.viewCount=this.supplyDetail.viewCount+1
    },
    linkToDetail(item) {  
      if (item.publisherType === 0) return;
      this.$router.push(`/exhibitor-detail/${item.publishId}?exhibitionId=${this.exhibitionId}`);
    },
    golinkToDemandDetail(id,isFollow){
      this.supplyId=id;
      this.isFollow=isFollow;
      this.isNew=false;
      this.init();
      this.getSupply();
    },
    rightClcik(){
      // console.log('右键了')
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.demand-detail {
  width: 1200px;
  margin: 0 auto;
}
.home-l {
  background-color: #fff;
  min-height: 500px;
  width: 100%;
  padding: 20px;
}
.detail {
  .avatarUrl {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin-right: 14px;
  }
  &-top {
    display: flex;
    position: relative;
  }
  .top-right {
    margin-top: 5px;
    cursor: pointer;
    .name {
      font-size: 18px;
      color: #333;
      font-weight: bold;
      span {
        margin-left: 10px;
        color:#666;
        font-size: 14px;
      }
    }
    .company {
      color:#666;
    }
    .follow {
      position: absolute;
      right: 0;
      top: 8px;
      width: 148px;
      height: 40px;
      background-color: #1890ff;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .email {
    min-width: 300px;
    max-width: 400px;
    height: 66px;
    background-color: #f7f7f7;
    border-radius: 3px;
    margin-top: 20px;
    padding: 13px;
  }
  .email1 {
    width: 360px;
    height: 66px;
    background-color: #f7f7f7;
    border-radius: 3px;
    margin-top: 20px;
    padding: 13px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
  }
  .title {
    margin-top: 20px;
    &-span {
      background: #ffefe5;
      padding: 5px;
      border-radius: 3px;
      color: #ff5e00;
      font-size: 12px;
    }
    &-span2 {
      margin-left: 10px;

      font-size: 16px;
    }
  }
  .content {
    p {
      font-size: 14px;
      margin-top: 20px;
    }
  }
  .demand-img {
    display: flex;
    margin-top: 20px;
    img {
      width: 150px;
      height: 150px;
      margin-right: 15px;
    }
  }
   .demand_video{
    min-height: 100px;
    margin-top: 20px;
    max-width: 420px;
    video{
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .demand-bottom {
    margin-top: 30px;
    position: relative;
    font-size: 14px;
    color:#666;
    span {
      margin-right: 20px;
    }
    .time {
      display: inline-block;
      position: absolute;
      right: 0;
    }
  }
}
.fenge {
  height: 10px;
  background: #f7f7f7;
}
.code {
  width: 310px;
  height: 310px;
  background-color: #ffffff;
  border-radius: 2px;
  border: solid 1px #ededed;
  text-align: center;
  padding-top: 30px;
}
.demand-r {
  min-height: 400px;
  background-color: #fff;
  margin-left: 20px;

  &-top {
    height: 50px;

    align-items: center;
    border-bottom: 1px solid #ededed;
  }

  &-list {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  .supply {
    width: 266px;
    height: 210px;
    background-color: #f7f7f7;
    border-radius: 3px;
    position: relative;
    margin-right: 16px;
    margin-bottom: 20px;

    &-top {
      display: flex;
      >div{
        max-width: calc(100% - 52px);
      }
      .headerimg {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .name {
        display: flex;
        align-items: center;
        max-width: 100%;
        font-size: 14px;
        color: #333;
        overflow: hidden;
        .name1 {
          max-width: 70%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          flex-shrink: 0;
        }
        .name2 {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-left: 8px;
          font-size: 12px;
          color: #666;
        }
      }

      .company {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 190px;
        color: #666;
        font-size: 12px;
      }

      .guanzhu {
        width: 65px;
        height: 24px;
        @include background_color(#1890ff);
        border-radius: 3px;
        position: absolute;
        top: 10px;
        right: 10px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .yiguanzhu {
        width: 65px;
        height: 24px;
        background-color: #fff;
        border-radius: 3px;
        border: 1px solid #1890ff;
        @include border_color(#1890ff);
        position: absolute;
        top: 10px;
        right: 10px;
        @include font_color(#1890ff);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    &-center {
      margin-top: 5px;

      .category {
        width: 58px;
        height: 20px;
        background-color: #ffefe5;
        border-radius: 3px;
        font-size: 12px;
        color: #ff5e00;
        padding: 4px;
        margin-right: 5px;
        text-align: center;
        overflow: hidden;
      }

      .category-content {
        line-height: 24px;
        height: 24px;
        color: #333;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .category-text2 {
        height: 28px;
        margin-top: 10px;
        font-size: 12px;
        color: #333;
        margin-bottom: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.2;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        span {
          color: #666;
        }
      }
    }

    &-bottom {
      margin-top: 10px;
      position: relative;
      display: flex;

      .img1 {
        width: 50px;
        height: 50px;
      }

      .img2 {
        width: 42px;
        height: 42px;
        position: absolute;
        top: 8px;
        left: 18px;
      }

      .img3 {
        width: 34px;
        height: 34px;
        position: absolute;
        top: 16px;
        left: 36px;
      }

      .img4 {
        width: 26px;
        height: 26px;
        position: absolute;
        top: 24px;
        left: 54px;
      }

      .img5 {
        width: 18px;
        height: 18px;
        position: absolute;
        top: 32px;
        left: 72px;
      }

      .time {
        font-size: 12px;
        color: #999;
        position: absolute;
        right: 0;
        top: 8px;
      }
    }
  }
}
.video_wrap{
    .top_video{
      width: 50px;
      height: 50px;
      position: relative;
      img{
        width: 100%;
        height: 100%;
        display: block;
      }
      .video_mask{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba($color: #000000, $alpha: 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        i{
          color: #fff;
        }
      }
    }
  }
</style>